import React, { useEffect, useState, useRef } from "react";
import Layout from "../components/layout";
import { useAuth } from "../authProvider";
import { LinkIcon } from "@heroicons/react/20/solid";
import Loader from "../components/loadingSpinner";
import ButtonPrimary from "../components/buttons/buttonPrimary";
import Alert from "../components/alerts/alert";
import { useNavigate } from "react-router-dom";
import { Text } from "@geist-ui/core";
import { InboxArrowDownIcon } from "@heroicons/react/24/outline";
import HCaptcha from '@hcaptcha/react-hcaptcha'
import { Logo } from "../components/icons/Logo";

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

const SignIn = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [emailIsDirty, setEmailIsDirty] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [captchaToken, setCaptchaToken] = useState();
  const captcha = useRef();

  const handleSignIn = async (e) => {
    e.preventDefault();

    if (!emailRegex.test(email)) {
      setAlert({ type: "ERROR", message: "Error! Invalid email address." });
      setEmailIsDirty(true);
      return;
    }

    try {
      setLoading(true);
      setDisabled(true);

      const { data, error } = await auth.login(email, captchaToken);

      if (error) {
        setAlert({ type: "ERROR", message: `${error.message}"."` });
        setDisabled(false);
        setEmailSent(false);
        setEmail("");
      }

      if (error) {
        setAlert({ type: "ERROR", message: error.message });
      } else {
        setEmailSent(true);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
      setDisabled(false);
      setEmailIsDirty(false);
      captcha.current.resetCaptcha();
    }
  };

  useEffect(() => {
    if (auth.session) {
      setEmailSent(true);
      navigate("/workouts");
    }
  });

  function backToSignIn() {
    setEmailSent(false);
    setEmail("");
  }

  return (
    <Layout navVisibility={true}>
      {auth.session ? (
        <h1>Sign In success</h1>
      ) : (
        <div className="flex flex-col w-full min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="w-full max-w-md space-y-4">
            <div>
              {emailSent ? (
                <>
                  <InboxArrowDownIcon
                    className="mx-auto h-8 w-auto"
                    alt="Logo"
                  />{" "}
                  <Text h1 className="mt-6 text-center font-bold text-white">
                    Check your email!
                  </Text>
                </>
              ) : (
                <>
                  <Logo className="mx-auto h-12 w-auto" alt="Logo" />
                  <Text h1 className="mt-6 text-center font-bold text-white">
                    Sign In
                  </Text>
                </>
              )}
            </div>
            {emailSent ? (
              <div className="flex flex-col items-center">
                <Text h4>A login link has been sent to {email}.</Text>
                <Text small i type="secondary" className="mt-2">
                  Didn't receive an email?{" "}
                  <span
                    className="underline cursor-pointer hover:text-white"
                    onClick={() => backToSignIn()}
                  >
                    Try again.
                  </span>
                </Text>
              </div>
            ) : (
              <form className="mt-8 space-y-4" onSubmit={handleSignIn}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="space-y-px rounded-md shadow-sm">
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      id="email-address"
                      name="email"
                      //type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="email"
                      required
                      autoFocus
                      className={`relative block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-emerald-600 focus:outline-none focus:ring-emerald-600 sm:text-sm disabled:opacity-75 ${
                        emailIsDirty ? "border-red-500" : ""
                      }`}
                      placeholder="Enter your email address"
                      disabled={disabled}
                    />
                  </div>
                  <div className="pt-4">
                  <HCaptcha ref={captcha} sitekey="6bcab864-b2a8-4db5-83c2-af1ccc07e527" onVerify={(token) => setCaptchaToken(token)} size="normal" theme="dark" />
                  </div>
                </div>
                <ButtonPrimary
                  type={"submit"}
                  disabled={disabled}
                  className={"relative w-full"}
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <LinkIcon
                      className="h-5 w-5 text-emerald-600 group-hover:text-emerald-500"
                      aria-hidden="true"
                    />
                  </span>
                  {loading ? <Loader /> : "Send Magic Link"}
                </ButtonPrimary>
                {alert && (
                  <Alert type={alert.type} clearAlert={() => setAlert(null)}>
                    {alert.message}
                  </Alert>
                )}
              </form>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default SignIn;
