import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useMemo,
} from "react";
import { supabase } from "../../supabaseClient";
import { useAuth } from "../../authProvider";

const userContext = React.createContext();

export const UserProvider = ({ children }) => {
  const user = useProvideUser();
  return <userContext.Provider value={user}>{children}</userContext.Provider>;
};

export const useUser = () => {
  return useContext(userContext);
};

function useProvideUser() {
  const auth = useAuth();
  const [user, setUser] = useState(null);
  const [prs, setPrs] = useState(null);

  const getUser = async () => {
    console.log("Getting user!");

    let { data, error, status } = await supabase
      .from("profiles")
      .select()
      .eq("id", auth.session.user.id)
      .single();

    if (error && status !== 406) {
      throw error;
    }

    /*
    if (data) {
      setUser(data);
    }
    */

    return { data, error };
  };

  const updateUser = async (updates) => {
    const { data, error } = await supabase
      .from("profiles")
      .upsert(updates)
      .select()
      .single();

    if (error) {
      console.log(error.message);
      // throw error;
    }
    if (data) {
      setUser(data);
    }

    return { data, error };
  };

  const getPRs = async () => {
    let { data, error } = await supabase
      .rpc("getusersprs")
      .eq("user_id", auth.session.user.id);

    if (error) {
      throw error;
    }

    /*
    if (data) {
      setPrs(data);
    }
    */

    return { data, error };
  };

  const createPR = async (newPR) => {
    let { data, error } = await supabase
      .from("users_prs")
      .insert(newPR)
      .select()
      .single();

    if (error) {
      throw error;
    }

    if (data) {
      const currentPRs = [...prs];

      // find previous PR
      const index = currentPRs.findIndex(
        (obj) => obj.exercise_id === newPR.exercise_id
      );

      if (index > -1) {
        // if a PR already exists..
        currentPRs[index] = newPR;
      } else {
        // else .. add new PR
        currentPRs.push(newPR);
      }

      setPrs(currentPRs);
    }

    return { data, error };
  };

  useEffect(() => {
    if (auth.session) {
      if (user) {
        console.log("User already available");
      } else {
        getUser()
          .then(({ data }) => {
            setUser(data);
          })
          .catch((error) => console.log(error.message));
      }

      if (prs) {
        console.log("PRs already available");
      } else {
        getPRs()
          .then(({ data }) => {
            setPrs(data);
          })
          .catch((error) => console.log(error.message));
      }
    } else {
      setUser(null);
      setPrs(null);
    }
  }, [auth.session]);

  return { user, updateUser, prs, createPR };
}
