import React, { useEffect, useState, useContext } from "react";
import { useAuth } from "../authProvider";
import { supabase } from "../supabaseClient";
import Layout from "../components/layout";
import { useUser } from "../components/contexts/userContext";
import Loader from "../components/loadingSpinner";
import ButtonSecondary from "../components/buttons/buttonSecondary";
import ButtonPrimary from "../components/buttons/buttonPrimary";
import Alert from "../components/alerts/alert";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";
import toast from "react-hot-toast";

const Profile = () => {
  const auth = useAuth();
  const { user, setUser, updateUser } = useUser();

  const [username, setUsername] = useState(null);
  const [weight_unit, setWeight_unit] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [alert, setAlert] = useState(null);
  const [editable, setEditable] = useState(false);
  const [usernameIsDirty, setUsernameIsDirty] = useState(false);
  const [weight_unitIsDirty, setWeight_unitIsDirty] = useState(false);

  useEffect(() => {
    if (auth.session.user) {
      if (user) {
        setLoadingProfile(false);
        setUsername(user.username);
        setWeight_unit(user.weight_unit);
        console.log("Profile already loaded.");
      }
    }
  }, [auth.session]);

  function verifyChange(e) {
    e.preventDefault();

    // Check username has more than 2 characters
    if (username.length > 64) {
      setAlert({
        type: "ERROR",
        message: "Error! Username is too long.",
      });
      setUsernameIsDirty(true);
      return;
    }
    if (!weight_unit === undefined) {
      setAlert({
        type: "ERROR",
        message: "Error! Invalid weight unit. Refresh the page and try again.",
      });
      weight_unitIsDirty(true);
      return;
    }

    updateProfile(e);
  }

  const updateProfile = async () => {
    toast.loading("Updating profile", { id: "updateProfile" });
    setLoadingSubmit(true);

    const updates = {
      id: auth.session.user.id,
      username,
      weight_unit,
      updated_at: new Date(),
    };

    updateUser(updates)
      .then(({ data }) => {
        toast.success("Profile updated.", { id: "updateProfile" });
        setUsername(data.username);
        setWeight_unit(data.weight_unit);
      })
      .catch((error) => {
        console.log(error.message);
        toast.error("Error updating profile.", { id: "updateProfile" });
      })
      .finally(() => {
        setLoadingSubmit(false);
        setEditable(false);
        setUsernameIsDirty(false);
        setWeight_unitIsDirty(false);
      });
  };

  function resetForm() {
    setEditable(false);
    setUsernameIsDirty(false);
    setWeight_unitIsDirty(false);
    setAlert(null);
    setUsername(user.username);
    setWeight_unit(user.weight_unit);
  }

  return (
    <Layout navVisibility={true}>
      <div className="h-full w-full overflow-scroll flex flex-col">
        <div className="w-full flex justify-end mb-6">
          <ButtonSecondary onClick={auth.logout} className={"relative pl-8"}>
            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
              <ArrowRightOnRectangleIcon
                className="h-4 w-4 text-white"
                aria-hidden="true"
              />
            </span>
            Sign out
          </ButtonSecondary>
        </div>
        <div className="flex-1 w-full space-y-4">
          <div className="bg-gray-700 border border-gray-600 rounded-md">
            <div className="flex justify-between items-center px-6 py-4 border border-transparent border-b-gray-600">
              <div className="text-base md:text-lg flex-start">Profile</div>
              <div className="flex-end">
                <ButtonSecondary
                  disabled={editable}
                  onClick={() => setEditable(true)}
                >
                  Edit
                </ButtonSecondary>
              </div>
            </div>
            {loadingProfile ? (
              <div className="w-full p-12 flex justify-center">
                <Loader />
              </div>
            ) : (
              <form className="m-0" onSubmit={verifyChange}>
                <div className="px-6 py-4 space-y-4">
                  <div className="text-sm text-gray-300">
                    <label htmlFor="username" className="block mb-2">
                      Username
                    </label>
                    <input
                      className={`w-full bg-gray-800 border border-gray-600 rounded-md px-4 py-2 text-sm focus:border-emerald-600 focus:outline-none focus:ring-emerald-600 disabled:bg-gray-700 disabled:text-gray-400 disabled:opacity-100 ${
                        usernameIsDirty ? "border-red-500" : ""
                      }`}
                      id="username"
                      type="text"
                      onChange={(e) => setUsername(e.target.value)}
                      value={username || ""}
                      autoComplete="false"
                      disabled={!editable || loadingSubmit}
                      maxLength={64}
                    />
                  </div>
                  <div className="text-sm text-gray-300">
                    <label htmlFor="email" className="block mb-2">
                      Email
                    </label>
                    <input
                      className="w-full bg-gray-800 border border-gray-600 rounded-md px-4 py-2 text-sm focus:border-emerald-600 focus:outline-none focus:ring-emerald-600 disabled:bg-gray-700 disabled:text-gray-400 disabled:opacity-100"
                      value={auth.session.user.email || ""}
                      autoComplete="false"
                      disabled
                    />
                  </div>
                  <div className="text-sm text-gray-300">
                    <label htmlFor="weight_unit" className="block mb-2">
                      Weight unit
                    </label>
                    <div className="relative">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
                        <span className="text-gray-400 text-sm">
                          {user.weight_unit === "kilos" ? "KG" : "LB"}
                        </span>
                      </div>
                      <select
                        className={`block w-full bg-gray-800 border border-gray-600 rounded-md pl-11 px-4 py-2 text-sm focus:border-emerald-600 focus:outline-none focus:ring-emerald-600 disabled:bg-gray-700 disabled:text-gray-400 disabled:opacity-100 ${
                          weight_unitIsDirty ? "border-red-500" : ""
                        }`}
                        disabled={!editable || loadingSubmit}
                        id="weight_unit"
                        value={weight_unit || ""}
                        onChange={(e) => setWeight_unit(e.target.value)}
                      >
                        <option value="kilos">Kilogram (Metric)</option>
                        <option value="pounds">Pounds (Imperial)</option>
                      </select>
                    </div>
                  </div>
                  {editable ? (
                    <div className="flex justify-end w-full space-x-2">
                      <ButtonSecondary disabled={false} onClick={resetForm}>
                        Cancel
                      </ButtonSecondary>
                      <ButtonPrimary type={"submit"} disabled={!editable}>
                        {loadingSubmit ? <Loader /> : "Save"}
                      </ButtonPrimary>
                    </div>
                  ) : (
                    ""
                  )}
                  {alert && (
                    <div>
                      <Alert
                        type={alert.type}
                        clearAlert={() => setAlert(null)}
                      >
                        {alert.message}
                      </Alert>
                    </div>
                  )}
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
