import React, { useEffect, useState, useContext } from "react";
import { useAuth } from "../authProvider";
import Layout from "../components/layout";
import Loader from "../components/loadingSpinner";
import ButtonPrimary from "../components/buttons/buttonPrimary";
import { parseDate } from "../components/parseHelper";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/24/outline";

const Analyze = () => {
  const auth = useAuth();

  useEffect(() => {
    if (auth.session.user) {
    }
  }, [auth.session]);

  return (
    <Layout navVisibility={true}>
      <div className="h-full w-full flex flex-col bg-gray-800 border border-gray-600 rounded-md">
        <div className="h-auto bg-gray-700 px-6 py-4 flex flex-row justify-between border border-transparent border-b-gray-600 items-center">
          <div className="text-base md:text-lg flex-start">Analyze</div>
        </div>
        <div className="flex-1 overflow-y-scroll bg-gray-700 w-full px-0 text-sm text-gray-300">
          <div className="w-full h-full flex justify-center items-center">
            Coming soon!
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Analyze;
