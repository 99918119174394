import {
  parseWeightSet,
  parsePercentageSet,
  parseComment,
  roundNumber,
  evaluateWeightSet,
  evaluateNonWeightSet,
  parseNonWeightSet,
} from "./parseHelper";
import { useRef } from "react";
import { useUser } from "./contexts/userContext";
import SetRow from "./editor/setRow";
import TextRow from "./editor/textRow";
import CommentRow from "./editor/commentRow";
import HeaderRow from "./editor/headerRow";
import AddPRModal from "./modals/addPRModal";

export default function Output({ outputJson, exercises = null }) {
  let currentExerciseSetIndex = 1;

  const { user, prs } = useUser();
  const AddPRModalRef = useRef();

  function handleDialog(currentExercisePR) {
    AddPRModalRef.current.toggleModal(currentExercisePR);
  }

  let currentExercise = null;
  let currentExercisePR = null;
  let exercisePR = null;
  let failedSet = false;

  return (
    <>
      {outputJson !== null &&
        exercises !== null &&
        prs !== null &&
        outputJson.content.map((node, index) => {
          const { content, type } = node;
          let set = {};
          let comment = "";
          failedSet = false;

          if (content && content.length > 0) {
            let paragraph = content[0].text;

            if (content.length === 1 && paragraph === undefined) {
              console.log("empty!");
              return <div key={index}>Content empty!</div>;
            }

            if (type && type == "exercise") {
              // reset previous values first in case
              // the next exercise doesn't have a PR
              currentExercise = null;
              currentExercisePR = null;
              exercisePR = null;
              currentExerciseSetIndex = 1;

              currentExercise = exercises.filter(
                (item) => item.name === paragraph
              );

              if (currentExercise.length > 0) {
                // Need to check if there's actually a valid exercise.
                // An exercise can be invalid when you hit the return key
                // on the exercise inside the editor.

                exercisePR = prs.filter(
                  (item) => item.exercise_id === currentExercise[0].exercise_id
                );

                if (exercisePR.length > 0) {
                  if (user.weight_unit === "kilos") {
                    currentExercisePR = roundNumber(exercisePR[0].pr.kilos);
                  } else if (user.weight_unit === "pounds") {
                    currentExercisePR = roundNumber(exercisePR[0].pr.pounds);
                  }
                }
                return (
                  <HeaderRow
                    key={index}
                    exercisePR={currentExercisePR}
                    handleDialog={handleDialog}
                    exercise={currentExercise[0]}
                  />
                );
              }
              return <HeaderRow key={index} notFound={true} />;
            } else if (currentExercise && currentExercise.length > 0) {
              // If there is not a complete exercise in the header skip
              failedSet = paragraph.endsWith("!");
              if (currentExercise[0].weights) {
                // check if it is an exercise that requires weights
                switch (evaluateWeightSet(paragraph)) {
                  case "weightSet":
                    set = parseWeightSet(paragraph, user.weight_unit);
                    return (
                      <SetRow
                        key={index}
                        set={set}
                        weight_unit={user.weight_unit}
                        pr={currentExercisePR}
                        currentExercise={currentExercise[0]}
                        failedSet={failedSet}
                        lineNumber={currentExerciseSetIndex++}
                      />
                    );
                  case "percentageSet":
                    if (currentExercisePR !== null) {
                      // PR exists
                      set = parsePercentageSet(
                        paragraph,
                        user.weight_unit,
                        currentExercisePR
                      );
                      return (
                        <SetRow
                          key={index}
                          set={set}
                          weight_unit={user.weight_unit}
                          pr={currentExercisePR}
                          currentExercise={currentExercise[0]}
                          failedSet={failedSet}
                          lineNumber={currentExerciseSetIndex++}
                        />
                      );
                    } else {
                      return (
                        <TextRow key={index}>
                          Set 1RM weight for this exercise first!
                        </TextRow>
                      );
                    }
                  case "comment":
                    comment = parseComment(paragraph);
                    return <CommentRow key={index}>{comment}</CommentRow>;

                  default:
                    return <TextRow key={index}>-</TextRow>;
                }
              } else {
                switch (evaluateNonWeightSet(paragraph)) {
                  case "nonWeightSet":
                    set = parseNonWeightSet(paragraph);
                    return (
                      <SetRow
                        key={index}
                        set={set}
                        weight_unit={user.weight_unit}
                        pr={currentExercisePR}
                        currentExercise={currentExercise[0]}
                        failedSet={failedSet}
                        lineNumber={currentExerciseSetIndex++}
                      />
                    );
                  case "comment":
                    comment = parseComment(paragraph);
                    return <CommentRow key={index}>{comment}</CommentRow>;

                  default:
                    return <TextRow key={index}>-</TextRow>;
                }
              }
            } else {
              return <TextRow key={index}>No exercise set!</TextRow>;
            }
          } else {
            return <TextRow key={index}></TextRow>;
          }
        })}
      <AddPRModal ref={AddPRModalRef} />
    </>
  );
}