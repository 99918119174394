import React, { useEffect, useState } from "react";
import { useAuth } from "../authProvider";
import { supabase } from "../supabaseClient";
import Layout from "../components/layout";
import UserContext, { useUser } from "../components/contexts/userContext";
import Loader from "../components/loadingSpinner";
import ButtonSecondary from "../components/buttons/buttonSecondary";
import ButtonPrimary from "../components/buttons/buttonPrimary";
import { parseDate } from "../components/parseHelper";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";

const Workouts = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [loadingWorkouts, setLoadingWorkouts] = useState(true);
  const { user } = useUser();
  const [workouts, setWorkouts] = useState(null);

  useEffect(() => {
    if (auth.session.user) {
      if (workouts && workouts.length >= 0) {
        console.log("Workouts already loaded");
      } else {
        console.log("Loading workouts");
        getUserWorkouts();
      }
    }
  }, [auth.session]);

  const getUserWorkouts = async () => {
    try {
      setLoadingWorkouts(true);

      let { data, error } = await supabase
        .from("workouts")
        .select(`id, name, created_at, updated_at`)
        .eq("user_id", auth.session.user.id)
        .order("created_at", { ascending: false });

      if (error) {
        throw error;
      }

      if (data) {
        setWorkouts(data);
      }
    } catch (error) {
      toast.loading("Error retrieving workouts.");
      console(error.message);
    } finally {
      setLoadingWorkouts(false);
    }
  };

  const createWorkout = async () => {
    let date = parseDate(new Date());
    let workoutName = `Workout - ${date.slice(0, date.indexOf("-") - 1)}`;

    try {
      toast.loading("Creating workout.", { id: "createWorkout" });
      let { data, error } = await supabase
        .from("workouts")
        .insert({ name: workoutName, user_id: auth.session.user.id })
        .select("id, name, created_at, updated_at")
        .single();

      if (error) {
        throw error;
      }

      if (data) {
        let tmp_workouts = workouts;

        tmp_workouts.push(data);

        setWorkouts(tmp_workouts);
        toast.success("Workout created.", {
          id: "createWorkout",
          duration: 1500,
        });
        navigate(`/workouts/${data.id}`);
      }
    } catch (error) {
      toast.error("Error creating workout.", { id: "createWorkout" });
      console.log(error.message);
    } finally {
    }
  };

  const deleteWorkout = async (id) => {
    console.log("Deleting workout: ", id);

    try {
      toast.loading("Deleting workout.", { id: "deleteWorkout" });

      let { status, error } = await supabase
        .from("workouts")
        .delete()
        .eq("id", id);

      if (error) {
        throw error;
      }

      if (status === 204) {
        setWorkouts(workouts.filter((workout) => workout.id !== id));
        localStorage.removeItem(`${id}`);
        toast.success("Workout deleted.", { id: "deleteWorkout" });
      }
    } catch (error) {
      toast.error("Error deleting workout!", { id: "deleteWorkout" });
      console.log(error.message);
    } finally {
    }
  };
  return (
    <Layout navVisibility={true}>
      <div className="h-full w-full flex flex-col bg-gray-800 border border-gray-600 rounded-md">
        <div className="h-auto bg-gray-700 px-6 py-4 flex flex-row justify-between border border-transparent border-b-gray-600 items-center">
          <div className="text-base md:text-lg flex-start">Workouts</div>
          <div className="flex-end">
            <ButtonSecondary size="md" onClick={() => createWorkout()}>
              New Workout
            </ButtonSecondary>
          </div>
        </div>
        <div className="flex-1 overflow-y-scroll bg-gray-700 w-full px-0 text-sm text-gray-300">
          {loadingWorkouts ? (
            <div className="w-full p-12 flex justify-center">
              <Loader />
            </div>
          ) : workouts && workouts.length > 0 ? (
            workouts.map((workout, index) => (
              <div
                key={workout.id}
                className="w-full flex flex-row items-center border border-transparent border-b-gray-600 p-4 hover:bg-gray-800 focus:bg-gray-800"
              >
                <Link
                  className="flex-1 cursor-pointer p-2"
                  to={`/workouts/${workout.id}`}
                >
                  <h4 className="text-white font-medium">{workout.name}</h4>
                  <span className="text-xs font-semibold">
                    {parseDate(workout.created_at)}
                  </span>
                </Link>
                <span
                  onClick={() => deleteWorkout(workout.id)}
                  className="ml-20 text-gray-400 hover:text-white cursor-pointer p-2"
                >
                  <TrashIcon
                    className="h-6 w-6 md:h-4 md:w-4"
                    aria-hidden="true"
                  />
                </span>
              </div>
            ))
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <ButtonPrimary size="md" onClick={() => createWorkout()}>
                Create Workout
              </ButtonPrimary>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Workouts;
