import { useState, useEffect, useContext, createContext } from "react";
import { supabase } from "../../supabaseClient";
import { useAuth } from "../../authProvider";

const exerciseContext = createContext();

export const ExerciseProvider = ({ children }) => {
  const exercises = useProvideExercises();
  return (
    <exerciseContext.Provider value={exercises}>
      {children}
    </exerciseContext.Provider>
  );
};

export const useExercises = () => {
  return useContext(exerciseContext);
};

function useProvideExercises() {
  const auth = useAuth();
  const [exercises, setExercises] = useState(null);

  const getExercises = async () => {
    console.log("Getting exercises!");
    const { data, error } = await supabase.from("exercises").select();

    if (error) {
      console.log(error);
    }

    return { data, error };
  };

  useEffect(() => {
    if (auth.session) {
      if (exercises && exercises.length > 0) {
        console.log("Exercises already available");
      } else {
        getExercises().then(({ data }) => {
          setExercises(data);
        });
      }
    }
  }, [auth.session]);

  return { exercises };
}
