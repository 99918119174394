import React, {
  useEffect,
  useRef,
  forwardRef,
  useState,
  useImperativeHandle,
} from "react";
import { Button, Modal, Text, useModal } from "@geist-ui/core";
import ButtonSecondary from "../buttons/buttonSecondary";
import ButtonPrimary from "../buttons/buttonPrimary";
import toast from "react-hot-toast";
import { useAuth } from "../../authProvider";
import Alert from "../../components/alerts/alert";
import Loader from "../../components/loadingSpinner";
import { useUser } from "../contexts/userContext";
import { convertToKilos, convertToPounds } from "../parseHelper";

const AddPRModal = forwardRef((props, ref) => {
  const { user, prs, createPR } = useUser();
  const auth = useAuth();

  const { visible, setVisible, bindings } = useModal();
  const [prIsDirty, setPrIsDirty] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [newPR, setNewPR] = useState(null);
  const [updatePR, setUpdatePR] = useState(null);

  useImperativeHandle(ref, (currentExercisePR) => ({
    toggleModal(currentExercisePR) {
      setUpdatePR(currentExercisePR);
      if (currentExercisePR.pr) {
        // set newPR to the existing one so the input field is autopopulated
        setNewPR(currentExercisePR.pr);
      }

      setVisible(true);
      //window.scrollTo({top: 0, left: 0, behavior: "smooth"})
    },
  }));

  function verifyChange(e) {
    e.preventDefault();

    // Check if PR is not exceeding limits
    if (newPR < 0 || newPR > 1000 || isNaN(newPR)) {
      setPrIsDirty(true);
      return;
    }
    handleCreatePR(e);
  }

  const handleCreatePR = async () => {
    toast.loading("Adding PR.", { id: "createPR" });
    setLoadingSubmit(true);

    let kilos = -1;
    let pounds = -1;

    if (user.weight_unit === "kilos") {
      pounds = convertToPounds(newPR);
      kilos = parseFloat(newPR);
    } else if (user.weight_unit === "pounds") {
      kilos = convertToKilos(newPR);
      pounds = parseFloat(newPR);
    }

    const updates = {
      exercise_id: updatePR.exercise_id,
      pr: { kilos: kilos, pounds: pounds },
      updated_at: new Date(),
      user_id: auth.session.user.id,
    };

    createPR(updates)
      .then(({ data }) => {
        toast.success("PR added.", { id: "createPR" });
        setVisible(false);
      })
      .catch((error) => {
        console.log(error.message);
        toast.error("Error adding PR.", { id: "createPR" });
      })
      .finally(() => {
        setLoadingSubmit(false);
        setPrIsDirty(false);
        setNewPR(null);
        setUpdatePR(null);
      });
  };

  function resetForm() {
    setNewPR(null);
    setPrIsDirty(null);
    setUpdatePR(null);
    setLoadingSubmit(false);
    setVisible(false);
  }

  return (
    <Modal {...bindings} className={"modal"} wrapClassName={"modal"}>
      <div className="h-auto p-4 flex flex-row justify-between border border-transparent border-b-gray-600 items-center">
        <div className="sm:text-base text-lg flex-start font-semibold">
          Update PR
        </div>
      </div>
      <div className="p-4 flex flex-col justify-start space-y-4">
        <form className="m-0" onSubmit={verifyChange}>
          <div className="w-full text-left space-y-4">
            <div className="text-sm text-gray-300">
              <label htmlFor="pr" className="block mb-2">
                New 1RM
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
                  <span className="text-gray-400 text-sm">
                    {user.weight_unit === "kilos" ? "KG" : "LB"}
                  </span>
                </div>
                <input
                  className={`w-full bg-gray-800 border border-gray-600 rounded-md pl-11 px-4 py-2 text-sm focus:border-emerald-600 focus:outline-none focus:ring-emerald-600 disabled:bg-gray-700 disabled:text-gray-400 disabled:opacity-100 ${
                    prIsDirty ? "border-red-500" : ""
                  }`}
                  id="pr"
                  onChange={(e) => setNewPR(e.target.value)}
                  value={newPR || ""}
                  type={"number"}
                  autoComplete="false"
                  disabled={loadingSubmit}
                  required
                  autoFocus
                  autoCorrect="false"
                />
              </div>
            </div>

            <div className="flex justify-end space-x-2">
              <ButtonSecondary type={"reset"} onClick={resetForm}>
                Cancel
              </ButtonSecondary>
              <ButtonPrimary type={"submit"}>
                {loadingSubmit ? <Loader /> : "Save"}
              </ButtonPrimary>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
});

export default AddPRModal;
