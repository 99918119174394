import React from "react";
import { roundNumber } from "../parseHelper";
import { Tag } from "@geist-ui/core";

const failedStyle = "line-through text-red-500 opacity-70";

const SetRow = ({
  set = null,
  weight_unit = null,
  pr = null,
  currentExercise = null,
  failedSet = false,
  lineNumber = null
}) => {
  return (
    <>
      {currentExercise.weights ? (
        <div className="text-sm md:text-base leading-6 md:leading-8 my-2 font-medium font-mono flex text-center items-center align-middle">
          <span className="w-1/5 flex justify-center items-center space-x-2">
            <span className={`${failedSet ? failedStyle : ""}`}>
              {set.numberOfReps}
            </span>
            <Tag scale={0.1} type="lite" invert>
              &#215;
            </Tag>
          </span>
          <span className="w-2/5 flex justify-end items-center space-x-1">
            <span className={`${failedSet ? failedStyle : ""}`}>
              {weight_unit === "kilos" ? set.kilos : set.pounds}
            </span>
            <Tag scale={0.1} type="lite" invert>
              {weight_unit === "kilos" ? "kg" : "lb"}
            </Tag>
          </span>
          <span className="w-2/5 flex justify-end items-center space-x-1">
            <span className={`${failedSet ? failedStyle : ""}`}>
              {pr !== null
                ? weight_unit === "kilos"
                  ? roundNumber((set.kilos / pr) * 100, 1)
                  : roundNumber((set.pounds / pr) * 100, 1)
                : "?"}
            </span>
            <Tag scale={0.1} type="lite" invert>
              %
            </Tag>
          </span>
          <span className="ml-1.5 text-xxs md:text-xs font-normal text-right text-gray-500">{lineNumber}</span>
        </div>
      ) : (
        <div className="text-sm md:text-base leading-6 md:leading-8 my-2 font-medium font-mono flex text-center items-center align-middle">
          <span className="w-full flex justify-center items-center space-x-2">
            <span className={`${failedSet ? failedStyle : ""}`}>
              {set.numberOfReps}
            </span>
            <Tag scale={0.1} type="lite" invert>
              &#215;
            </Tag>
          </span>
          <span className="ml-1.5 text-xxs md:text-xs font-normal text-right text-gray-500">{lineNumber}</span>
        </div>
      )}
    </>
  );
};

export default SetRow;
