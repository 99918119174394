import React, { useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { useAuth } from "../authProvider";
import useWindowDimensions from "../useWindowDimensions";
import { ActivityIcon } from "./icons/ActivityIcon";
import { Logo } from "./icons/Logo";
import { UserIcon } from "./icons/UserIcon";
import { WorkoutListIcon } from "./icons/WorkoutListIcon";

const Layout = ({ children, navVisibility }) => {
  const auth = useAuth();

  /*
  const nav = [
    // { name: "Log", to: "/workouts/new" },
    { name: "Analyze", to: "/analyze", icon: <ActivityIcon /> },
    { name: "Workouts", to: "/workouts", icon: <WorkoutListIcon /> },
    { name: "Profile", to: "/profile", icon: <UserIcon /> },
  ];
  */

  return (
    <div className="flex flex-col w-full md:flex-row-reverse h-full text-white dynamicHeight">
      <div className="flex flex-1 bg-gray-800 overflow-hidden">
        <div className="flex w-full md:w-[80%] md:mx-auto text-sm py-2 px-2 md:px-0 md:py-6 overflow-hidden">
          {children}
        </div>
      </div>
      {auth.session && (
        <div className="h-auto md:h-full flex justify-center md:justify-start overflow-y-hidden bg-gray-900">
          <nav
            className={`w-full flex md:flex-col md:justify-between p-2 md:py-6 overflow-x-hidden ${
              !navVisibility && "hidden md:flex"
            }`}
          >
            <ul className="w-full flex flex-row md:flex-col space-z-0 md:space-y-4 space-x-8 md:space-x-0 p-2 text-center">
              <li className="hidden md:block md:w-full mb-4">
                <div className="text-white h-full px-4 flex flex-col items-center justify-center text-sm font-medium [&_svg]:h-8 [&_svg]:w-8">
                  <Logo />
                  <span>Snatch App</span>
                </div>
              </li>
              <li className="w-1/3 md:w-full h-12 md:h-10">
                <NavLink
                  to="/analyze"
                  className={({ isActive }) =>
                    `${
                      isActive
                        ? "bg-emerald-800 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white"
                    } h-full px-4 flex flex-row items-center justify-center md:justify-start text-md rounded-md font-medium [&_svg]:h-6 [&_svg]:w-6`
                  }
                >
                  <ActivityIcon />
                  <span className="hidden sm:block ml-2">Analyze</span>
                </NavLink>
              </li>
              <li className="w-1/3 md:w-full h-12 md:h-10">
                <NavLink
                  to="/workouts"
                  className={({ isActive }) =>
                    `${
                      isActive
                        ? "bg-emerald-800 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white"
                    } h-full px-4 flex flex-row items-center justify-center md:justify-start text-md rounded-md font-medium [&_svg]:h-6 [&_svg]:w-6`
                  }
                >
                  <WorkoutListIcon />
                  <span className="hidden sm:block ml-2">Workouts</span>
                </NavLink>
              </li>
              <li className="w-1/3 md:w-full h-12 md:h-10 md:hidden">
                <NavLink
                  to="/profile"
                  className={({ isActive }) =>
                    `${
                      isActive
                        ? "bg-emerald-800 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white"
                    } h-full px-4 flex flex-row items-center justify-center md:justify-start text-md rounded-md font-medium [&_svg]:h-6 [&_svg]:w-6`
                  }
                >
                  <UserIcon />
                  <span className="hidden sm:block ml-2">Profile</span>
                </NavLink>
              </li>
            </ul>
            <div className="hidden md:flex md:flex-col md:p-2">
              <div className="md:w-full md:h-10">
                <NavLink
                  to="/profile"
                  className={({ isActive }) =>
                    `${
                      isActive
                        ? "bg-emerald-800 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white"
                    } h-full px-4 flex flex-row items-center justify-start text-md rounded-md font-medium [&_svg]:h-6 [&_svg]:w-6`
                  }
                >
                  <UserIcon />
                  <span className="ml-2">Profile</span>
                </NavLink>
              </div>
            </div>
          </nav>
        </div>
      )}
    </div>
  );
};
export default Layout;
