export const Logo = ({className}) => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={`w-8 h-8 ${className}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5455 5.45454H5.45455C3.64707 5.45454 2.18182 6.91979 2.18182 8.72727V15.2727C2.18182 17.0802 3.64707 18.5455 5.45455 18.5455H18.5455C20.3529 18.5455 21.8182 17.0802 21.8182 15.2727V8.72727C21.8182 6.91979 20.3529 5.45454 18.5455 5.45454ZM5.45455 4.36363C3.04458 4.36363 1.09091 6.3173 1.09091 8.72727V15.2727C1.09091 17.6827 3.04458 19.6364 5.45455 19.6364H18.5455C20.9554 19.6364 22.9091 17.6827 22.9091 15.2727V8.72727C22.9091 6.3173 20.9554 4.36363 18.5455 4.36363H5.45455Z"
        fill="currentColor"
      />
      <path
        d="M6 11.7273C6 12.1791 5.63369 12.5455 5.18182 12.5455C4.72995 12.5455 4.36364 12.1791 4.36364 11.7273C4.36364 11.2754 4.72995 10.9091 5.18182 10.9091C5.63369 10.9091 6 11.2754 6 11.7273Z"
        fill="currentColor"
      />
      <path
        d="M8.72728 8.45455C8.72728 8.90642 8.36096 9.27273 7.90909 9.27273C7.45722 9.27273 7.09091 8.90642 7.09091 8.45455C7.09091 8.00268 7.45722 7.63637 7.90909 7.63637C8.36096 7.63637 8.72728 8.00268 8.72728 8.45455Z"
        fill="currentColor"
      />
      <path
        d="M8.72728 11.7273C8.72728 12.1791 8.36096 12.5455 7.90909 12.5455C7.45722 12.5455 7.09091 12.1791 7.09091 11.7273C7.09091 11.2754 7.45722 10.9091 7.90909 10.9091C8.36096 10.9091 8.72728 11.2754 8.72728 11.7273Z"
        fill="currentColor"
      />
      <path
        d="M11.4545 8.45455C11.4545 8.90642 11.0882 9.27273 10.6364 9.27273C10.1845 9.27273 9.81818 8.90642 9.81818 8.45455C9.81818 8.00268 10.1845 7.63637 10.6364 7.63637C11.0882 7.63637 11.4545 8.00268 11.4545 8.45455Z"
        fill="currentColor"
      />
      <path
        d="M11.4545 11.7273C11.4545 12.1791 11.0882 12.5455 10.6364 12.5455C10.1845 12.5455 9.81818 12.1791 9.81818 11.7273C9.81818 11.2754 10.1845 10.9091 10.6364 10.9091C11.0882 10.9091 11.4545 11.2754 11.4545 11.7273Z"
        fill="currentColor"
      />
      <path
        d="M14.1818 8.45455C14.1818 8.90642 13.8155 9.27273 13.3636 9.27273C12.9118 9.27273 12.5455 8.90642 12.5455 8.45455C12.5455 8.00268 12.9118 7.63637 13.3636 7.63637C13.8155 7.63637 14.1818 8.00268 14.1818 8.45455Z"
        fill="currentColor"
      />
      <path
        d="M14.1818 11.7273C14.1818 12.1791 13.8155 12.5455 13.3636 12.5455C12.9118 12.5455 12.5455 12.1791 12.5455 11.7273C12.5455 11.2754 12.9118 10.9091 13.3636 10.9091C13.8155 10.9091 14.1818 11.2754 14.1818 11.7273Z"
        fill="currentColor"
      />
      <path
        d="M19.6364 8.45455C19.6364 8.90642 19.2701 9.27273 18.8182 9.27273C18.3663 9.27273 18 8.90642 18 8.45455C18 8.00268 18.3663 7.63637 18.8182 7.63637C19.2701 7.63637 19.6364 8.00268 19.6364 8.45455Z"
        fill="currentColor"
      />
      <path
        d="M19.6364 15C19.6364 15.4519 19.2701 15.8182 18.8182 15.8182C18.3663 15.8182 18 15.4519 18 15C18 14.5481 18.3663 14.1818 18.8182 14.1818C19.2701 14.1818 19.6364 14.5481 19.6364 15Z"
        fill="currentColor"
      />
      <path
        d="M16.9091 8.45455C16.9091 8.90642 16.5428 9.27273 16.0909 9.27273C15.639 9.27273 15.2727 8.90642 15.2727 8.45455C15.2727 8.00268 15.639 7.63637 16.0909 7.63637C16.5428 7.63637 16.9091 8.00268 16.9091 8.45455Z"
        fill="currentColor"
      />
      <path
        d="M16.9091 11.7273C16.9091 12.1791 16.5428 12.5455 16.0909 12.5455C15.639 12.5455 15.2727 12.1791 15.2727 11.7273C15.2727 11.2754 15.639 10.9091 16.0909 10.9091C16.5428 10.9091 16.9091 11.2754 16.9091 11.7273Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0909 15.5455H7.90909V14.4545H16.0909V15.5455Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2727 13.5C15.4987 13.5 15.6818 13.6832 15.6818 13.9091V16.0909C15.6818 16.3168 15.4987 16.5 15.2727 16.5C15.0468 16.5 14.8636 16.3168 14.8636 16.0909V13.9091C14.8636 13.6832 15.0468 13.5 15.2727 13.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4545 13.5C14.6805 13.5 14.8636 13.6832 14.8636 13.9091V16.0909C14.8636 16.3168 14.6805 16.5 14.4545 16.5C14.2286 16.5 14.0455 16.3168 14.0455 16.0909V13.9091C14.0455 13.6832 14.2286 13.5 14.4545 13.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.72728 13.5C8.95321 13.5 9.13637 13.6832 9.13637 13.9091V16.0909C9.13637 16.3168 8.95321 16.5 8.72728 16.5C8.50134 16.5 8.31819 16.3168 8.31819 16.0909V13.9091C8.31819 13.6832 8.50134 13.5 8.72728 13.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.54545 13.5C9.77139 13.5 9.95454 13.6832 9.95454 13.9091V16.0909C9.95454 16.3168 9.77139 16.5 9.54545 16.5C9.31952 16.5 9.13636 16.3168 9.13636 16.0909V13.9091C9.13636 13.6832 9.31952 13.5 9.54545 13.5Z"
        fill="currentColor"
      />
      <path
        d="M18 11.4545C18 11.1533 18.2442 10.9091 18.5455 10.9091H19.0909C19.3922 10.9091 19.6364 11.1533 19.6364 11.4545V12C19.6364 12.3012 19.3922 12.5455 19.0909 12.5455H18.5455C18.2442 12.5455 18 12.3012 18 12V11.4545Z"
        fill="currentColor"
      />
      <path
        d="M4.36364 8.18182C4.36364 7.88058 4.60785 7.63637 4.90909 7.63637H5.45455C5.7558 7.63637 6 7.88058 6 8.18182V8.72728C6 9.02852 5.7558 9.27273 5.45455 9.27273H4.90909C4.60785 9.27273 4.36364 9.02852 4.36364 8.72728V8.18182Z"
        fill="currentColor"
      />
      <path
        d="M4.36364 14.7273C4.36364 14.426 4.60785 14.1818 4.90909 14.1818H6C6.30125 14.1818 6.54546 14.426 6.54546 14.7273V15.2727C6.54546 15.574 6.30125 15.8182 6 15.8182H4.90909C4.60785 15.8182 4.36364 15.574 4.36364 15.2727V14.7273Z"
        fill="currentColor"
      />
    </svg>
  );
  