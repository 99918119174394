import { useAuth } from "../authProvider";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const auth = useAuth();

  return auth.session ? children : <Navigate to={"/"} />;
};

export default ProtectedRoute;
