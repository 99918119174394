import React, { useEffect } from "react";
import { useUser } from "../contexts/userContext";
import { Badge } from "@geist-ui/core";

const HeaderRow = ({
  exercise = null,
  exercisePR = null,
  handleDialog,
  notFound = false,
}) => {
  const { user, prs } = useUser();
  let unitString;
  let currentExercisePR;

  if (user.weight_unit === "kilos") {
    unitString = "kg";
  } else if (user.weight_unit === "pounds") {
    unitString = "lb";
  }

  if (!notFound) {
    currentExercisePR = {
      pr: exercisePR,
      exercise_id: exercise.exercise_id,
    };
  }

  return (
    <>
      {notFound ? (
        <div className="bg-gray-600 rounded-r-md mb-1 mt-5 pr-2 w-full text-xs md:text-sm leading-8 md:leading-8 my-2 font-semibold font-mono flex justify-center text-center items-center">
          <span className="w-full">Exercise not found!</span>
        </div>
      ) : exercise.weights ? (
        <div className="bg-gray-600 rounded-r-md mb-1 mt-5 pr-2 w-full text-xs md:text-sm leading-8 md:leading-8 my-2 font-semibold font-mono flex justify-center text-center items-center">
          <span className="w-1/5">reps</span>
          <span className="w-2/5 flex justify-end items-center">
            {unitString}
          </span>
          <span className="w-2/5 flex justify-end items-center">
            <Badge
              scale={0.7}
              onClick={() => handleDialog(currentExercisePR)}
              type="warning"
              className={"cursor-pointer"}
            >
              {exercisePR !== null
                ? exercisePR // + " " + unitString
                : "No PR"}
            </Badge>
          </span>
        </div>
      ) : (
        <div className="bg-gray-600 rounded-r-md mb-1 mt-5 pr-2 w-full text-xs md:text-sm leading-8 md:leading-8 my-2 font-semibold font-mono flex justify-center text-center items-center">
          <span className="w-full">reps</span>
        </div>
      )}
    </>
  );
};

export default HeaderRow;
