import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./authProvider";
import { ExerciseProvider } from "./components/contexts/exerciseContext";
import App from "./App";
import { GeistProvider, Themes } from "@geist-ui/core";

const myTheme1 = Themes.createFromLight({
  type: "darkTheme",
  palette: {
    background: "#353535",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <AuthProvider>
      <ExerciseProvider>
        <GeistProvider themes={[myTheme1]} themeType={"darkTheme"}>
          <App />
        </GeistProvider>
      </ExerciseProvider>
    </AuthProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
