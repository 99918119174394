import React from "react";

const TextRow = ({ children }) => {
  return (
    <>
      <div className="w-full text-xs h-6 md:h-8 min-h-6 md:min-h8 leading-3 md:leading-4 my-2 font-semibold flex justify-center text-center items-center">
        {children}
      </div>
    </>
  );
};

export default TextRow;
