import React from "react";
import { Badge } from "@geist-ui/core";

const CommentRow = ({ children }) => {
  return (
    <>
      <div className="pl-4 w-full text-sm md:text-base leading-6 md:leading-8 my-2 font-normal flex justify-center text-center items-center font-mono text-lime-700">
          {children}
      </div>
    </>
  );
};

export default CommentRow;
