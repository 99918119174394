import { EditorContent, useEditor } from "@tiptap/react";
import Paragraph from "@tiptap/extension-paragraph";
import Document from "@tiptap/extension-document";
import StarterKit from "@tiptap/starter-kit";
import Text from "@tiptap/extension-text";
import { Exercise } from "./exercise.ts";
// import suggestion from "./suggestion";
import Hashtag from "./hashtag.ts";
import Placeholder from "@tiptap/extension-placeholder";
import {
  multiSetToSingleSet,
  checkForMultiSet,
} from "../components/parseHelper";
import { ReactRenderer } from "@tiptap/react";
import tippy from "tippy.js";
import HashtagList from "./HashtagList.jsx";
import { useEffect } from "react";

export default function Editor({
  setOutputJson,
  exercises = null,
  inputJson = null,
  passEditor,
}) {
  const EnterListener = Paragraph.extend({
    addOptions() {
      return {
        HTMLAttributes: {
          class:
            "align-middle pl-1 text-sm md:text-base leading-6 md:leading-8 my-2 opacity-70",
        },
      };
    },

    addKeyboardShortcuts() {
      return {
        Backspace: () => {
          this.editor.commands.deleteSelection();

          setTimeout(() => {
            let jsonOutput = this.editor.getJSON();
            sendOutput(jsonOutput);
          }, "100");
        },
        Enter: () => {
          let jsonOutput = this.editor.getJSON();

          if (jsonOutput.content.length > 0) {
            parseEditor(jsonOutput, this.editor);
            sendOutput(jsonOutput);
          } else {
            sendOutput(jsonOutput);
          }
        },
      };
    },
  });

  let component;
  const suggestion = {
    command: ({ editor, range, props }) => {
      let exerciseNode = {};
      const nodeAfter = editor.view.state.selection.$to.nodeAfter;
      const overrideSpace = nodeAfter?.text?.startsWith(" ");

      if (overrideSpace) {
        range.to += 1;
      }
      range.from -= 1;

      exerciseNode = {
        type: "exercise",
        content: [{ type: "text", text: props.id }],
      };

      editor
        .chain()
        .focus()
        .insertContentAt(range, exerciseNode)
        .toggleHashtag()
        .run();

      let jsonOutput = editor.getJSON();

      if (jsonOutput.content.length > 0) {
        parseEditor(jsonOutput, editor);
        sendOutput(jsonOutput);
      } else {
        sendOutput(jsonOutput);
      }

      editor.commands.enter();

      window.getSelection()?.collapseToEnd();
    },
    items: async ({ query }) => {
      return exercises
        .filter((item) => {
          if (
            item.name.toLowerCase().includes(query.toLowerCase()) ||
            (item.alias &&
              item.alias.some((alias) =>
                alias.toLowerCase().includes(query.toLowerCase())
              ))
          ) {
            return item.name;
          }
        })
        .slice(0, 8);
    },
    render: () => {
      let popup;

      return {
        onBeforeStart: (props) => {
          component = new ReactRenderer(HashtagList, {
            props,
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy("body", {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
          });
        },
        onStart: (props) => {
          component.updateProps(props);
        },
        onBeforeUpdate(props) {
          component.updateProps(props);
        },
        onUpdate(props) {
          component.updateProps(props);
          if (!props.clientRect) {
            return;
          }
          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props) {
          if (props.event.key === "Escape") {
            popup[0].hide();
            return true;
          }

          return component.ref?.onKeyDown(props);
        },
        onExit() {
          popup[0].destroy();
          component.destroy();
        },
      };
    },
  };

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      StarterKit,
      Exercise,
      EnterListener,
      Hashtag.configure({
        HTMLAttributes: {
          class:
            "mention w-full bg-gray-600 pl-2 text-sm leading-6 md:leading-8 my-2 flex items-center font-bold rounded-l-md",
        },
        suggestion,
      }),
      Placeholder.configure({
        emptyEditorClass:
          "is-editor-empty text-gray-500 text-sm font-mono leading-5 md:leading-6",
        placeholder:
          "Type ⎵ (space) to add an exercise\nType reps@weight to enter a set\nType setsXreps@weight to repeat sets\nE.g.: 3x5@100",
      }),
    ],
    onCreate({ editor }) {
      const content = inputJson;
      if (content) {
        editor.commands.setContent(content);
        sendOutput(content);
      }
    },
    onUpdate({ editor }) {
      sendOutput(editor.getJSON());
    },
    editorProps: {
      attributes: {
        class: "pr-0 outline-none text-white font-mono",
        autocomplete: "off",
        autocorrect: "off",
        autofocus: "true",
      },
    },
    content: ``,
    autofocus: "start",
  });

  useEffect(() => {
    passEditor(editor);
  }, [editor]);

  return <EditorContent editor={editor} />;

  // send json to output component
  function sendOutput(json) {
    setOutputJson(json);
  }

  // parse for multisets
  function parseEditor(json, editor) {
    let includesMultiSet = false; // need to update editor content if text includes a multiset
    let contentBuilder = { ...json };

    json.content.forEach((node, index) => {
      const { content } = node;

      if (content && content.length > 0) {
        let paragraph = "";
        let paragraphType = "";
        paragraph = content[0].text;
        paragraphType = content[0].type;

        if (checkForMultiSet(paragraph)) {
          includesMultiSet = true;
          contentBuilder = multiSetToSingleSet(
            paragraph,
            contentBuilder,
            index
          );
        }
      }
    });

    if (includesMultiSet) {
      editor.commands.setContent(contentBuilder);
    }
  }
}
