import React from "react";

const ButtonSecondary = ({
  disabled = null,
  onClick = null,
  type = null,
  children,
  className = null,
  size = "sm",
}) => {
  let buttonSize = "text-sm py-1";

  switch (size) {
    case "md":
      buttonSize = "text-md py-2";
      break;
    case "lg":
      buttonSize = "text-lg py-3";
      break;
  }

  return (
    <button
      className={`h-auto bg-gray-700 border border-gray-600 px-4 rounded-md font-medium cursor-pointer hover:bg-gray-800 focus:bg-gray-700 focus:border-gray-600 focus:outline-none ${buttonSize} ${className}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};

export default ButtonSecondary;
