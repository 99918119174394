const weight_unit = "kilos";

export function parseWeightSet(text, unit) {
  let weightIndex = -1;
  let numberOfReps = -1;
  let weight = -1;
  let kilos = -1;
  let pounds = -1;
  let set = { numberOfReps: -1, kilos: -1, pounds: -1 };

  weightIndex = text.search(/@/);

  if (weightIndex > -1) {
    // User doesn't have to specify number of reps
    // if doing only one rep. They can enter @<weight>
    if (weightIndex === 0) {
      numberOfReps = 1;
    }

    if (weightIndex > 0) {
      // if there's a number of reps
      numberOfReps = parseInt(text.substring(0, weightIndex));
    }
    weight = parseFloat(text.substring(weightIndex + 1));

    switch (unit) {
      case "kilos":
        pounds = convertToPounds(weight);
        pounds = roundNumber(pounds);
        kilos = roundNumber(weight);
        break;
      case "pounds":
        kilos = convertToKilos(weight);
        pounds = roundNumber(weight);
        kilos = roundNumber(kilos);
        break;
      default:
        console.log("Warning! Can't read weight unit.");
        kilos = roundNumber(weight);
        pounds = roundNumber(weight);
        break;
    }

    set.numberOfReps = numberOfReps;
    set.kilos = kilos;
    set.pounds = pounds;
  }

  return set;
}

export function parseNonWeightSet(text) {
  let numberOfReps = -1;
  let set = { numberOfReps: -1 };

  numberOfReps = parseInt(text);

  if (isNaN(numberOfReps)) {
    numberOfReps = -1;
  }

  set.numberOfReps = numberOfReps;

  return set;
}

export function convertToKilos(value, userPR = -1) {
  let kilos = 0;
  if (value && value > 0) {
    if (userPR < 0) {
      // if percentage has been passed
      value = userPR * value;
    }
    kilos = value / 2.205;
  } else {
    console.log("Error: Can't convert null value to pounds.");
  }

  return Math.abs(roundNumber(kilos));
}

export function convertToPounds(value, userPR = -1) {
  let pounds = 0;
  if (value && value > 0) {
    if (userPR < 0) {
      // if percentage has been passed
      value = userPR * value;
    }
    pounds = value * 2.205;
  } else {
    console.log("Error: Can't convert null value to pounds.");
  }

  return Math.abs(roundNumber(pounds));
}

export function parsePercentageSet(text, unit, userPR) {
  let weightIndex = -1;
  let percentageIndex = -1;
  let numberOfReps = -1;
  let percentage = -1;
  let kilos = -1;
  let pounds = -1;
  let set = { numberOfReps: -1, kilos: -1, pounds: -1 };

  weightIndex = text.search(/@/);
  percentageIndex = text.search(/[pP%]/);

  if (weightIndex > -1) {
    // User doesn't have to specify number of reps
    // if doing only one rep. They can enter @<percentage>
    if (weightIndex === 0) {
      numberOfReps = 1;
    }

    if (weightIndex > 0) {
      numberOfReps = parseInt(text.substring(0, weightIndex));
    }
    percentage =
      parseFloat(text.substring(weightIndex + 1, percentageIndex)) / 100;

    switch (unit) {
      case "kilos":
        pounds = convertToPounds(percentage, userPR);
        pounds = roundNumber(pounds);
        kilos = roundNumber(userPR * percentage);
        break;
      case "pounds":
        kilos = convertToKilos(percentage, userPR);
        pounds = roundNumber(userPR * percentage);
        kilos = roundNumber(kilos);
        break;
      default:
        console.log("Warning! Can't read weight unit.");
        kilos = roundNumber(userPR * percentage);
        pounds = roundNumber(userPR * percentage);
        break;
    }

    set.numberOfReps = numberOfReps;
    set.kilos = kilos;
    set.pounds = pounds;
  }

  return set;
}

export function parseComment(text) {
  let commentIndex = -1;
  let comment = "";

  commentIndex = text.search(/#/);
  if (commentIndex > -1) {
    comment = text.substring(commentIndex + 1);
  }

  return comment;
}

export function roundNumber(number, decimals = 2) {
  return Number(Math.round(number + "e" + decimals) + "e-" + decimals);
}

// evaluates a weight set input
export function evaluateWeightSet(text) {
  const weightSetPattern = /^(\d+)?@\d+/gm; // e.g. 10@20 or @23
  const percentageSetPattern =
    /^(\d+)?@((([1-9][0-9]?[0-9]?((\.|,)[0-9]{0,2})?)))[%pP]/gm;
  const commentPattern = /^#\w+/gm; // e.g. #hello

  if (percentageSetPattern.test(text)) {
    return "percentageSet";
  } else if (weightSetPattern.test(text)) {
    return "weightSet";
  } else if (commentPattern.test(text)) {
    return "comment";
  }
  return "blank";
}

// evaluates a non-weight set input
export function evaluateNonWeightSet(text) {
  const helpPattern = /^(?:\?|help|Help)$/gm; // e.g. "?" or "help"
  const nonWeightSetPattern = /^(\d+[x,X,*])?\d+/gm; // e.g. 3x10 or 10
  const distancePattern = /^\d+((mi)|(m)|(km)|(k)|(ft))/gm;
  const caloriePattern = /^\d+((cal)|(CAL))/gm;
  const commentPattern = /^#\w+/gm; // e.g. #hello

  if (nonWeightSetPattern.test(text)) {
    return "nonWeightSet";
  } else if (commentPattern.test(text)) {
    return "comment";
  }
  return "blank";
}

// evaluates the input
export function checkForMultiSet(text) {
  const multiSetPattern = /^\d+[x,X,*](\d+)?(@\d+)?/gm; // e.g. 3x10@20 or 3x@23

  if (multiSetPattern.test(text)) {
    return true;
  }
  return false;
}

// converts a multiset 3x10@20 to a set of single sets
export function multiSetToSingleSet(paragraph, contentBuilder, index) {
  let numberOfSets = -1;
  let setIndex = -1;
  let singleSet = "";
  let newParagraph = {};

  setIndex = paragraph.search(/[xX\*]/); // get the index of the set operator (* | x | X)

  if (setIndex > 0) {
    // if there is a set operator
    singleSet = paragraph.substring(setIndex + 1); // extract the set e.g. 10@20
    numberOfSets = parseInt(paragraph.substring(0, setIndex)); // parse the number of sets

    // Build new set node
    newParagraph = {
      type: "paragraph",
      content: [{ type: "text", text: singleSet }],
    };

    // replace multiSet with singleSet
    contentBuilder.content.splice(index, 1, newParagraph);

    // duplicate singleSet for number of sets -1
    for (let i = 1; i < numberOfSets; i++) {
      contentBuilder.content.splice(index + i, 0, newParagraph);
    }
  } else {
    return null;
  }
  return contentBuilder;
}

export function parseJson(json, exercises, workoutId) {
  const { content } = { ...json };

  if (!content || content.length <= 0) {
    return null;
  }

  let logs = [];
  let log = {
    sets: [],
    exercise_id: -1,
    index: -1,
    workout_id: workoutId,
  };

  let currentSet = {};
  let currentExercise = null;
  let currentExerciseId = -1;
  let currentExerciseIndex = 0;

  let k = 0;

  for (let i = 0; i < content.length; i++) {
    k = i;
    while (k < content.length && content[k].type !== "exercise") {
      if (content[k].content) {
        currentSet = buildSet(content[k].content[0].text);
        log.sets.push(currentSet);
      }
      k++;
    }

    // If it is the first exercise
    if (k <= content.length && currentExercise !== null) {
      // If it is the n_th+1 exercise
      log.exercise_id = currentExerciseId;
      log.index = currentExerciseIndex;
      logs.push(log);

      i = k;
      log = {
        sets: [],
        exercise_id: -1,
        index: -1,
        workout_id: workoutId,
      };

      if (k < content.length) {
        if (content[i].content) {
          currentExercise = content[i].content[0].text;
          currentExerciseId = getExerciseID(currentExercise, exercises);
          currentExerciseIndex++;
        }
      }
    } else if (k <= content.length) {
      if (content[i].content) {
        currentExercise = content[i].content[0].text;
        console.log();
        currentExerciseId = getExerciseID(currentExercise, exercises);
        currentExerciseIndex++;
      }
    }
  }

  console.log("Finished logs: ", logs);
  return logs;
}

function getExerciseID(exerciseName, array) {
  // To Do
  // Make sure exercises that don't exist don't error

  const exercise = array.filter(function (item) {
    return item.name == exerciseName;
  });
  return exercise[0].exercise_id;
}

export function buildSet(text) {
  let set = {};

  switch (evaluateWeightSet(text)) {
    case "weightSet":
      set = parseWeightSet(text, weight_unit);
      break;
    case "percentageSet":
      set = parsePercentageSet(text, weight_unit);
      break;
    case "comment":
      set = { comment: parseComment(text) };
      break;
    default:
      set = { text: text };
      break;
  }

  return set;
}

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function parseDate(ts) {
  let result = "";

  const date = new Date(ts);

  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const weekday = date.toLocaleDateString("en-US", { weekday: "short" });

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  result =
    weekday +
    " " +
    months[month - 1] +
    " " +
    dt +
    ", " +
    year +
    " - " +
    hours +
    ":" +
    minutes;
  return result;
}
