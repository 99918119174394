import React, { useEffect, useState } from "react";
import { useAuth } from "../authProvider";
import Layout from "../components/layout";
import { supabase } from "../supabaseClient";
import Editor from "../components/editor";
import { useExercises } from "../components/contexts/exerciseContext";
import ButtonSecondary from "../components/buttons/buttonSecondary";
import { ArrowPathIcon } from "@heroicons/react/20/solid";
import { parseDate } from "../components/parseHelper";
import { useNavigate, useParams } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/20/solid";
import toast from "react-hot-toast";
import Output from "../components/output";
import { useUser } from "../components/contexts/userContext";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";

const Log = () => {
  let { workout_id } = useParams();
  const navigate = useNavigate();

  const auth = useAuth();
  const { user, prs } = useUser();
  const { exercises } = useExercises();

  const [outputJson, setOutputJson] = useState(null);
  const [editorRef, setEditorRef] = useState(null);
  const [workout, setWorkout] = useState(null);
  const [log, setLog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingWorkout, setLoadingWorkout] = useState(true);
  const [workoutSaved, setWorkoutSaved] = useState(true);
  const [savingWorkout, setSavingWorkout] = useState(false); // loading indicator for saving a workout
  const [countSaved, setCountSaved] = useState(0); // used to show saved state after log has been initilized

  useEffect(() => {
    if (auth.session.user) {
      if (workout && prs) {
        console.log("Workout already loaded");
        setLoading(false);
      } else {
        if (workout_id) {
          getWorkout();
        } else {
          console.log("Error! No workout_id.");
        }
      }
    }
  }, []);

  useEffect(() => {
    // indicate that the latest changes are not saved
    if (countSaved > 1) {
      //useEffect will be called twice on initialization
      // once countSaved >1 indicate that changes haven't been saved
      setWorkoutSaved(false);
    }
    let count = countSaved;
    count++;
    setCountSaved(count);
  }, [outputJson]);

  function navigateBack() {
    // if the workout is unsaved
    // save it to LocalStorage first
    if (!workoutSaved) {
      localStorage.setItem(`${workout_id}`, JSON.stringify(outputJson));
    }

    navigate("/workouts");
  }

  const getWorkout = async () => {
    try {
      setLoadingWorkout(true);
      setLoading(true);

      let { data, error } = await supabase
        .from("workouts")
        .select(
          `created_at, name, id, workout_logs_raw(workout_id, created_at, updated_at, log)`
        )
        .eq("user_id", auth.session.user.id)
        .eq("id", workout_id)
        .single();

      if (error) {
        throw error;
      }
      if (data) {
        const { created_at, name, id, workout_logs_raw } = data;
        setWorkout({ created_at: created_at, name: name, id: id });

        // check if there's unsaved changes in LocalStorage
        let unsavedLog = localStorage.getItem(`${workout_id}`);
        unsavedLog = JSON.parse(unsavedLog);
        if (unsavedLog) {
          //if there are, populate unsaved changes
          setLog(unsavedLog);

          // don't set the workout as "saved"
          setWorkoutSaved(false);
          setOutputJson(unsavedLog);
          toast.success("Unsaved changes recovered.", {
            iconTheme: {
              primary: "gray",
              secondary: "black",
            },
          });
        } else if (
          workout_logs_raw &&
          Object.keys(workout_logs_raw).length > 0
        ) {
          // if there aren't unsaved changes, populate the DB log
          setLog(workout_logs_raw.log);
          setWorkoutSaved(true);
        } else if (
          workout_logs_raw &&
          Object.keys(workout_logs_raw).length <= 0
        ) {
          // if this is a new workout
          // don't indicate the workout as "saved"

          setCountSaved(2);
          setWorkoutSaved(false);
        }
      }
    } catch (error) {
      toast.error("Error loading workout.", { id: "getWorkout" });
      navigate("/workouts");
      console.log("Error retrieving workout!", error.message);
    } finally {
      setLoadingWorkout(false);
      setLoading(false);
    }
  };

  const saveWorkout = async () => {
    const log = { ...outputJson };

    try {
      setSavingWorkout(true);
      toast.loading("Saving workout.", { id: "savingWorkout" });

      const { data, error } = await supabase
        .from("workout_logs_raw")
        .upsert({
          updated_at: new Date(),
          workout_id: workout.id,
          log: log,
          user_id: auth.session.user.id,
          weight_unit: user.weight_unit,
        })
        .select();

      if (error) {
        throw error;
      }
      if (data) {
        toast.success("Workout saved.", { id: "savingWorkout" });
        // remove from LocalStorage if exists
        localStorage.removeItem(`${workout_id}`);
      }
    } catch (error) {
      console.log("Error saving workout!", error.message);
      toast.error("Error saving workout!", { id: "savingWorkout" });
    } finally {
      setSavingWorkout(false);
      setWorkoutSaved(true);
    }
  };

  return (
    <Layout navVisiblity={false}>
      <>
        {loading || loadingWorkout ? (
          <div>loading...</div>
        ) : (
          <>
            <div className="flex flex-col w-full text-white text-sm">
              <div className="h-auto border border-transparent border-b-gray-600 bg-gray-800 w-full z-50 px-1 md:px-2 py-2 md:py-4 flex justify-between items-center">
                <div className="flex-start flex items-center">
                  <ArrowSmallLeftIcon
                    onClick={() => navigateBack()}
                    className="p-1 w-8 h-8 text-gray-300 hover:text-white focus:text-white cursor-pointer md:hidden mr-4"
                  />
                  <div>
                    <div className="text-sm md:text-lg font-semibold">
                      {workout.name}
                    </div>
                    <div className="text-xs font-semibold text-gray-300">
                      {parseDate(workout.created_at)}
                    </div>
                  </div>
                </div>
                <div className="flex-end">
                  {workoutSaved ? (
                    <ButtonSecondary
                      size={"md"}
                      className={"relative pl-8"}
                      onClick={saveWorkout}
                      disabled={true}
                    >
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <CheckIcon
                          className="h-3 w-3 text-white"
                          aria-hidden="true"
                        />
                      </span>
                      Saved
                    </ButtonSecondary>
                  ) : (
                    <ButtonSecondary
                      size={"md"}
                      className={"relative pl-8"}
                      onClick={saveWorkout}
                    >
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <ArrowPathIcon
                          className="h-3 w-3 text-white"
                          aria-hidden="true"
                        />
                      </span>
                      Save
                    </ButtonSecondary>
                  )}
                </div>
              </div>
              <div
                onClick={() => editorRef.commands.focus()}
                className="flex h-full p-0 overflow-y-auto"
              >
                <div className="h-full md:w-1/2 w-1/2">
                  <Editor
                    setOutputJson={setOutputJson}
                    exercises={exercises}
                    inputJson={log}
                    passEditor={setEditorRef}
                  />
                </div>
                <div className="h-full md:w-1/2 w-1/2 pl-0 mr-0">
                  <Output outputJson={outputJson} exercises={exercises} />
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </Layout>
  );
};
export default Log;

/*
const addWorkout = async () => {
    const json = { ...outputJson };

    let logs = [];

    logs = parseJson(json, exercises, workout_id);
    console.log(logs);

    const { data, error } = await supabase.from("workout_logs").insert(logs);
    if (error) {
      console.log(error);
    }
    if (data) {
      console.log("Workout has been created!");
    }
  };
*/
