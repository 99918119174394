import React from "react";

const ButtonPrimary = ({
  disabled = null,
  type = null,
  onClick = null,
  children,
  className = null,
  size = "sm",
}) => {
  let buttonSize = "text-sm py-1";

  switch (size) {
    case "md":
      buttonSize = "text-md py-2";
      break;
    case "lg":
      buttonSize = "text-lg py-3";
      break;
  }
  return (
    <button
      className={`text-white h-auto bg-emerald-800 border border-transparent px-4 rounded-md font-medium cursor-pointer hover:bg-emerald-700 focus:border-emerald-600 focus:outline-none focus:ring-emerald-600 ${buttonSize} ${className}`}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default ButtonPrimary;
