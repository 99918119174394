import { mergeAttributes, Node, nodeInputRule, textblockTypeInputRule } from '@tiptap/core'

export interface exerciseOptions {
  exitOnTripleEnter: boolean,
  exitOnArrowDown: boolean,
  HTMLAttributes: Record<string, any>,
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    exercise: {
      /**
       * Toggle a workSet
       */
      setExercise: () => ReturnType,
      toggleExercise: () => ReturnType,
    }
  }
}

export const inputRegex = /^:[\s]$/gm;

export const Exercise = Node.create<ExerciseOptions>({
  name: 'exercise',

  priority: 1000,

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'w-full bg-gray-600 pl-2 text-xs md:text-sm leading-8 md:leading-8 font-bold rounded-l-md mb-1 mt-5 truncate overflow-hidden text-ellipsis',
      },
    }
  },

  content: 'inline*',

  group: 'block list',

  parseHTML() {
    return [
      { tag: 'p' },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['p', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setExercise: () => ({ commands }) => {
        return commands.setNode(this.name)
      },
      toggleExercise: () => ({ commands }) => {
        return commands.toggleNode(this.name, 'paragraph')
      },
    }
  },
})