import React, { useEffect, useRef } from "react";

const Alert = ({
  type = null,
  onClick = null,
  children,
  className = null,
  clearAlert = null,
}) => {
  const ref = useRef(null);
  let classes = "";

  switch (type) {
    case "SUCCESS":
      classes = "bg-emerald-700";
      break;
    case "WARNING":
      classes = "bg-yellow-500";
      break;
    case "ERROR":
      classes = "bg-red-700";
      break;
    case "INFO":
      classes = "bg-blue-500";
      break;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      // Reset the alert after 5 seconds
      clearAlert();
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      ref={ref}
      className={`flex w-full justify-center rounded-md border border-transparent text-sm font-medium text-white py-2 px-4 text-black ${classes} ${className}`}
    >
      {children}
    </div>
  );
};

export default Alert;
