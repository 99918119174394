import React, { useState, useMemo, useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import Log from "./pages/Log";
import SignIn from "./pages/SignIn";
import ProtectedRoute from "./components/protectedRoute";
import Profile from "./pages/profile";
import { UserProvider } from "./components/contexts/userContext";
import Workouts from "./pages/Workouts";
import Analyze from "./pages/Analyze";
import { Toaster } from "react-hot-toast";
const App = () => {
  return (
    <BrowserRouter basename={"/"}>
      <UserProvider>
        <Routes>
          <Route
            path="/workouts/:workout_id"
            element={
              <ProtectedRoute>
                <Log />
              </ProtectedRoute>
            }
          />
          <Route path={"/"} element={<SignIn />} />
          <Route
            path={"profile"}
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            index
            path={"workouts"}
            element={
              <ProtectedRoute>
                <Workouts />
              </ProtectedRoute>
            }
          />
          <Route
            path={"analyze"}
            element={
              <ProtectedRoute>
                <Analyze />
              </ProtectedRoute>
            }
          />
        </Routes>
        <Toaster
          position="bottom-center"
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options
            className: "",
            duration: 3500,
            style: {
              background: "#000",
              color: "#fff",
              fontSize: "0.85rem",
            },
          }}
        />
      </UserProvider>
    </BrowserRouter>
  );
};

export default App;
