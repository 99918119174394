import React, {
  forwardRef, useEffect, useImperativeHandle,
  useState,
} from 'react'

export default forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectItem = index => {
    const item = props.items[index].name

    if (item) {
      props.command({ id: item })
    }
  }

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  useEffect(() => setSelectedIndex(0), [props.items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  return (
    <div className="bg-gray-900 border border-gray-300 rounded-md relative overflow-hidden">
      <div className="flex flex-col bg-dark-900 rounded-md shadow-lg overflow-hidden relative">
        <div className="">
        {props.items.length
          ? props.items.map((item, index) => (
            <div
              className={`text-base text-typography-body-dark px-4 py-1.5 flex items-center space-x-2 cursor-pointer hover:bg-gray-700 focus:bg-gray-700 border-none ${index === selectedIndex ? 'bg-gray-700 border-none' : ''}`}
              key={index}
              onClick={() => selectItem(index)}
            >
              <span className="">{item.name}</span>
            </div>
          ))
          : <div className="text-base text-typography-body-dark px-4 py-1.5 flex items-center space-x-2 cursor-pointer focus:bg-gray-700 border-none"><span className="">No Results</span></div>
        }
        </div>
      </div>
    </div>
  )
})